<template>
  <div
    class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  >
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
          <router-link
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
            to="/subscribers"
          >
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
            {{ $t("new_subscriber") }}
          </div>
          <div v-else class="text-2xl font-bold text-green-500">
            {{ $t("edit_subscriber") }}
          </div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6">
          <div class="space-y-4 px-10">
            <div
              class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="fullName" class="bg-white text-gray-700 px-1"
                    >{{ $t("full_name")
                    }}<span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <input
                  id="fullName"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.fullName"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>

            <div
              class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="phone" class="bg-white text-gray-700 px-1"
                    >{{ $t("phone") }}
                    <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <input
                  id="phone"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.phone"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>

            <div
              class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="customerPhone" class="bg-white text-gray-700 px-1"
                    >{{ $t("country") }}
                    <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <v-select
                  @input="setSelectedContry"
                  v-model="formData.country"
                  :options="warehouses"
                  label="countryName"
                >
                  <template slot="option" slot-scope="option">
                    <img
                      class="flag-img"
                      :src="$f.getCountryCode(option.country)"
                    />
                    {{ option.countryName }}
                  </template>
                </v-select>
              </p>
            </div>

            <div
              class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="email" class="bg-white text-gray-700 px-1"
                    >{{ $t("email") }}
                    <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <input
                  id="email"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.email"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>
          </div>

          <div class="mt-6 pt-3 flex justify-center">
            <button
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
              @click="save"
            >
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- ******************* Partie Script *********************** -->
<script>
export default {
  data() {
    return {
      formData: {},
      warehouses: [],
      selectContry: "",
      options: this.$countries,
    };
  },
  async mounted() {
    this.dataToEdit();
    await this.getWarehouses();
  },
  methods: {
    async getWarehouses() {
      const filter = { limit: 1000 };
      const res = await this.$server.search("warehouses", filter);
      if (res.content.results) {
        this.warehouses = res.content.results;
        // for(let i in data){
        // this.warehouses.push({name:this.$f.getCountryNameByCode(this.options,data[i].country),code:data[i].country})
        //}
      } else this.warehouses = [];
    },
    async setSelectedContry(contry) {
      if (contry) {
        if (
          typeof this.formData.country === "object" &&
          this.formData.country !== null
        )
          this.formData.country = this.formData.country.countryName;
      }
    },
    async save() {
      //
      if (this.formData._id) {
        var count = this.formData.country.length;
        if (count > 2)
          this.formData.country = this.$f.getCountryCodeByName(
            this.options,
            this.formData.country
          );
        const data = await this.$server.update("subscribers", this.formData);
        if (data._id) {
          alert(this.$t("subscriber_updated"), "success");
        }
      } else {
        if (this.checkData()) {
          var count = this.formData.country.length;
          if (count > 2)
            this.formData.country = this.$f.getCountryCodeByName(
              this.options,
              this.formData.country
            );

          const data = await this.$server.create("subscribers", this.formData);
          //
          //

          if (data._id) {
            this.selectContry = "";
            this.formData = {};
            alert(this.$t("subscriber_created"), "success");
          }
        } else {
          alert(this.$t("all_required_field"), "warning");
        }
      }
    },
    checkData() {
      const requiredFiels = ["fullName", "phone", "email", "country"];
      for (let index = 0, len = requiredFiels.length; index < len; index++) {
        if (!(requiredFiels[index] in this.formData)) return false;
      }
      return true;
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("subscribers", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        var count = this.formData.country.length;
        if (count == 2)
          this.formData.country = this.$f.getCountryNameByCode(
            this.options,
            this.formData.country
          );
      }
    },
  },
};
</script>

<!-- ******************* Partie style *********************** -->
<style></style>
