var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('router-link', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3",
    class: _vm.$colors.primary,
    attrs: {
      "to": "/subscribers"
    }
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_subscriber")) + " ")]) : _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("edit_subscriber")) + " ")])], 1)]), _c('div', {}, [_c('div', {
    staticClass: "bg-white rounded-lg p-6"
  }, [_c('div', {
    staticClass: "space-y-4 px-10"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "fullName"
    }
  }, [_vm._v(_vm._s(_vm.$t("full_name"))), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.fullName,
      expression: "formData.fullName"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "fullName",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.fullName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "fullName", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "phone"
    }
  }, [_vm._v(_vm._s(_vm.$t("phone")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.phone,
      expression: "formData.phone"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "phone",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "phone", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerPhone"
    }
  }, [_vm._v(_vm._s(_vm.$t("country")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.warehouses,
      "label": "countryName"
    },
    on: {
      "input": _vm.setSelectedContry
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.country)
          }
        }), _vm._v(" " + _vm._s(option.countryName) + " ")];
      }
    }]),
    model: {
      value: _vm.formData.country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "country", $$v);
      },
      expression: "formData.country"
    }
  })], 1)]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("email")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.email,
      expression: "formData.email"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "email",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "email", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }